import React from 'react';
import './Home.css'; // Import CSS for styling
import TeamMemberCarousel from './TeamMemberCarousel';
import illustration_1 from './assets/illustration_1.webp';
import illustration_2 from './assets/illustration_2.webp';

function Home() {
  return (
    <div className="home">
      {/* <div className="bg-white text-dark p-5">
      <section className="search-section container">
        <div className="row">
            <div className="col">
            <h3>P5 Tech Inc.</h3>
            </div>
        </div>
        </section>
      </div> */}

{/* style={{ backgroundImage: `url("https://images.unsplash.com/photo-1487088678257-3a541e6e3922?q=80&w=3348&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")` }} */}
      <div className="bg-dark bg-opacity-50"  >
        {/* bg-dark bg-gradient */}
        <div style={{ position: "relative" }} className="  text-white p-5">
          
          <section className="search-section container">

            <div className=''>
              <div className="row">
                <div className="col-1"></div>
                <div className="col p-5">
                  <h3>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</h3>
                  <button type="button" className="w-75 btn rounded-5 ps-5 pe-5 btn-outline-light mt-4">Learn More</button>
                </div>
                <div className="col-4">
                  <div className="h-100">
                    <img className="img invert img-rounded rounded-3  img-responsive w-100 " src={illustration_2} />
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
              {/* <div className="row mt-3">
            <div className="col-3">
            
            </div>
        </div> */}
            </div>
          </section>

        </div>
      </div>
      <div className="container mt-5">
        <section className="features row">
          <div className="col-md-3 feature">
            <div className="w-100">
              <img className="w-100" src={illustration_1} />
            </div>
          </div>
          <div className="col-md-3 mb-1 feature">
            <div className="bg-white rounded-3 bg-opacity-50 p-3 small h-100">
              <h5>Discover amazing features</h5>
              <p className="fw-light">Please note that we provide these marketplace and other resources to you only as a learning platform and convenience. Inclusion of any link does not imply endorsement, approval or recommendation. As always be careful and never share your Wallet private keys with anyone. Make sure you are connected to testnet before approving any transactions.</p>
            </div>
          </div>
          <div className="col-md-3 mb-1 feature">
            <div className="bg-white rounded-3 bg-opacity-50 p-3 small h-100">
              <h5>Search with ease</h5>
              <p className="fw-light">Please note that we provide these marketplace and other resources to you only as a learning platform and convenience. Inclusion of any link does not imply endorsement, approval or recommendation. As always be careful and never share your Wallet private keys with anyone. Make sure you are connected to testnet before approving any transactions.</p>
            </div>
          </div>
          <div className="col-md-3 mb-1 feature">
            <div className="bg-white rounded-3 bg-opacity-50 p-3 small h-100">
              <h5>Find what you need</h5>
              <p className="fw-light">Let's take a look at what a typical NFT Marketplace design looks like. Don't worry if you see something you don't know as we will revisit the same design at the end and hopefully you will know everything by that time.</p>
            </div>
          </div>
        </section>
      </div>

      <div className="bg-light bg-gradient bg-opacity-25 mt-4 mb-4">
        <div className="container">
          <TeamMemberCarousel />
        </div>
      </div>


      <div className="container mt-4">
        <div className="row">
          <div className="col-md-8 offset-md-2 p-4 text-center">
            <h1>Trusted by 27,000+ companies</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
