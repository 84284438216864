import React from 'react';


const Footer = () => {
  return (
    <div className='container'>
        <div className="row">
          <div className="col-md-4 p-3">
          Spread the Love!<br/>Follow P5 Tech on X

          </div>
          <div className="col-md-4 p-3">
           <b>Learn More</b>
           <ul className=''>
            <li className=''><a href="#">Link One</a></li>
            <li className=''><a href="#">Link One</a></li>
            <li className=''><a href="#">Link One</a></li>
            <li className=''><a href="#">Link One</a></li>
           </ul>
          </div>
          <div className="col-md-4 p-3">
          <b>Disclaimer</b> 
        <p className="small">Please note that we provide these marketplace and other resources to you only as a learning platform and convenience. Inclusion of any link does not imply endorsement, approval or recommendation. As always be careful and never share your Wallet private keys with anyone. Make sure you are connected to testnet before approving any transactions.</p>
            <a href="#">Read our Privacy Policy</a>
          </div>
        </div>
    </div>
  );
};

export default Footer;
