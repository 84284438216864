import React from 'react';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Handle, Position,
    NodeToolbar,
    Panel,
    applyEdgeChanges, applyNodeChanges
} from 'reactflow';

// import { useStore } from '../store';

export default function DefaultNode({ id, data, onDeleteClick, onCopyClick, onExpandClick }) {
    return (
        <>
      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Right}>
        <div className='btn-group'>
          {/* Call the event handlers when buttons are clicked */}
          <button className="btn btn-secondary" onClick={onDeleteClick}>
            <i className='fa fa-trash'></i>
          </button>
          <button className="btn btn-secondary" onClick={onCopyClick}>
            <i className='fa fa-copy'></i>
          </button>
          <button className="btn btn-secondary" onClick={onExpandClick}>
            <i className='fa fa-expand'></i>
          </button>
        </div>
      </NodeToolbar>
            
            <Handle
                type="target"
                position={Position.Top}
                id="a"
            />
            <div
                style={{
                    border: '1px solid #B8B8B8',
                    borderRadius: '8px',
                    padding: '8px',
                    background: 'white',
                }}
            >
                <div className="container" style={{ maxWidth: "250px" }}>
                    <div className="row">
                        <div className="float-start">
                            <div style={{
                                width: '8px',
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                bottom: "10px",
                                borderRadius: '8px 8px',
                                background: data.borderColor,
                            }}></div>
                        </div>
                        <div className="ms-2 mt-2 col-1 node-icon center">
                            <i style={{ color: data.borderColor }} className={`mt-1 w-100  fa ${data.icon}`}></i>
                        </div>
                        <div className="mt-2 col node-label">
                            <b className="small p-0 m-0">{data.label}</b>
                            <p className="small">{data.description}</p>
                        </div>
                    </div>
                </div>

            </div>
            <Handle
                type="source"
                position={Position.Bottom}
                id="b"
            />
        </>
    );
};