import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import SidePanel from './SidePanel';
import DefaultNode from './DefaultNode';
import StartNode from './StartNode';
import EditPanel from './EditPanel';
import EdgeEditModal from './EdgeEditModal'
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Handle, Position,
    NodeToolbar,
    Panel,
    isEdge,
    applyEdgeChanges, applyNodeChanges
} from 'reactflow';
import 'reactflow/dist/style.css';

const customNodeTypes = {
    decision: [
        {
            label: 'Condition',
            type: 'decision',
            event: 'condition',
            icon: 'fa-flag',
            borderColor: '#0ABBBD',
            inputs: [],
            outputs: []
        },
        {
            label: 'Event',
            type: 'decision',
            event: 'event',
            icon: 'fa-calendar',
            borderColor: '#4C96FC',
            inputs: [],
            outputs: []
        },
    ],
    action: [
        {
            label: 'Email',
            type: 'action',
            event: 'email',
            icon: 'fa-envelope',
            borderColor: '#B8B8B8',
            inputs: [
                {
                    id: 'to',
                    label: 'To',
                    inputType: "select",
                    values: [
                        "vdparikh@gmail.com",
                        "hello@vishalparikh.me"
                    ]
                },
                {
                    id: 'textarea',
                    label: 'Textarea',
                    inputType: "textarea"
                },
                {
                    id: 'checkbox',
                    label: 'Checkbox Button',
                    inputType: "checkbox",
                    values: [
                        "vdparikh@gmail.com",
                        "hello@vishalparikh.me"
                    ]
                },
                {
                    id: 'radio',
                    label: 'Radio Button',
                    inputType: "radio",
                    values: [
                        "vdparikh@gmail.com",
                        "hello@vishalparikh.me"
                    ]
                },
                {
                    id: 'subject',
                    label: 'Subject',
                },
            ],
            outputs: [
                {
                    id: 'sent',
                    label: 'Sent',
                },
            ],
        },
        {
            label: 'SMS',
            type: 'action',
            event: 'sms',
            icon: 'fa-comment',
            borderColor: '#B8B8B8',
            inputs: [
                {
                    id: 'phone',
                    label: 'Phone Number',
                },
                {
                    id: 'message',
                    label: 'Message',
                },
            ],
            outputs: [
                {
                    id: 'sent',
                    label: 'Sent',
                },
            ],
        },
        {
            label: 'Push Notification',
            type: 'action',
            event: 'push-notification',
            icon: 'fa-bell',
            borderColor: '#B8B8B8',
            inputs: [],
            outputs: [
                {
                    id: 'channel',
                    label: 'channel',
                    jsonPath: 'data.channel',
                    dataType: 'string',
                }
            ]
        },
    ],
};

const startNodes = [
    {
        id: '1',
        type: 'startNode',
        position: { x: 0, y: 0 },
        data: {
            label: "Start Node",
            icon: 'fa-flag',
            type: 'start',
            event: 'start',
            description: "Fun starts here",
            inputs: [],
            outputs: [],
        },
    },
];

const Dashboard = () => {
    const nodeTypes = useMemo(() => ({ defaultNode: DefaultNode, startNode: StartNode }), []);
    const [showNodes, setShowNodes] = useState(false);
    const [nodes, setNodes] = useState(startNodes);
    const [edges, setEdges] = useState([]);
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null);

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );

    const handleNodeDoubleClick = (event, node) => {
        setSelectedNode(node);
        setSelectedNodeId(node.id);
        // setEditedNodeName(node.data.label);
        // setEditedNodeDescription(node.data.description);

        // console.log(node.data.editedNodeData);
        // if (node.data.editedNodeData !== undefined) {
        //     setEditedNodeData(node.data.editedNodeData);
        // }
        setShowNodes(false)
        console.log(node.id);
    };

    const unselectNodeId = (event) => {
        setSelectedNodeId(null);
        setSelectedNode(null);
    };


    // Function to save the edited node's name
    const saveEditedNode = (updatedNodeData) => {
        console.log(updatedNodeData);
        // Find the selected node in the nodes state array
        const updatedNodes = nodes.map((node) => {
            if (node.id === updatedNodeData.id) {
                console.log({
                    ...updatedNodeData.data,
                    label: updatedNodeData.data.label,
                    description: updatedNodeData.data.description,
                    editedNodeData: updatedNodeData.data.editedNodeData
                });
                // Update the name of the selected node
                return {
                    ...node,
                    data: {
                        ...updatedNodeData.data,
                        label: updatedNodeData.data.label,
                        description: updatedNodeData.data.description,
                        editedNodeData: updatedNodeData.data.editedNodeData
                    },
                };
            }
            return node;
        });

        // Update the nodes state with the edited node's name
        setNodes(updatedNodes);
        setSelectedNode(updatedNodeData);

        // Clear the editedNodeName state variable
        // setEditedNodeName('');
        // setEditedNodeDescription('')
        // unselectNodeId()

    }

      // Define your click event handlers here
  const handleDeleteClick = (nodeId) => {
    // Implement the delete logic here
    console.log(`Delete button clicked for node with ID: ${nodeId}`);
  };

  const handleCopyClick = (nodeId) => {
    // Implement the copy logic here
    console.log(`Copy button clicked for node with ID: ${nodeId}`);
  };

  const handleExpandClick = (nodeId) => {
    // Implement the expand logic here
    console.log(`Expand button clicked for node with ID: ${nodeId}`);
  };

    const addNode = (type, event, label, icon, description, borderColor, inputs, outputs) => {
        // Create a new node with a unique ID
        console.log(inputs)
        var nodeId = `node-${nodes.length + 1}`
        const newNode = {
            id: nodeId,
            type: 'defaultNode',
            position: { x: 0, y: 0 },
            onDeleteClick: (nodeId) => handleDeleteClick(nodeId),
            onCopyClick: () => handleCopyClick(nodeId),
            onExpandClick: () => handleExpandClick(nodeId),
            data: {
                label,
                type,
                event,
                icon,
                description,
                borderColor,
                inputs: inputs || [],
                outputs: outputs || [],
            },
        };
        setNodes((prevNodes) => [...prevNodes, newNode]);
    };

    // Load saved state when the component mounts
    const loadFlow = () => {
        console.log("loading from localStorage");
        const savedState = localStorage.getItem('flowState');
        if (savedState) {
            const parsedState = JSON.parse(savedState);
            console.log(parsedState);
            if (Array.isArray(parsedState.nodes) && Array.isArray(parsedState.edges)) {
                setNodes(parsedState.nodes);
                setEdges(parsedState.edges);
            } else {
                console.error('Invalid saved state format');
            }
        } else {
            // If no saved state is found, use the initial state
            setNodes([]);
            setEdges([]);
        }
    };

    const saveFlow = () => {
        console.log("saving to localStorage:", { nodes, edges }); // Debugging statement
        const stateToSave = JSON.stringify({ nodes, edges });
        localStorage.setItem('flowState', stateToSave);
    };


    // State to store the currently edited edge
    const [editingEdge, setEditingEdge] = useState(null);

    // Handler for double-click on edges
    const handleEdgeDoubleClick = (event, edge) => {
        if (isEdge(edge)) {
            // Set the editingEdge state to the clicked edge
            setEditingEdge(edge);
        }
    };

    // Handler for saving edge changes
    const handleEdgeSave = (newLabel, newType, newAnimated, newStroke) => {
        // Find the index of the editing edge in the edges array
        console.log(newStroke);
        const edgeIndex = edges.findIndex((edge) => edge.id === editingEdge.id);

        if (edgeIndex !== -1) {
            // Create a copy of the edges array
            const updatedEdges = [...edges];

            // Update the label and type of the editing edge
            updatedEdges[edgeIndex] = {
                ...updatedEdges[edgeIndex],
                label: newLabel,
                type: newType,
                animated: newAnimated,
                style: newStroke,
            };

            // Set the updated edges array and clear the editingEdge state
            setEdges(updatedEdges);
            setEditingEdge(null);
        }
    };
    return (
        <div className='bg-white'>
            <div className="navbar bg-secondary">
                <div className="ms-2 btn-group">
                    <button className="btn btn-sm btn-outline-light" onClick={() => { unselectNodeId(); setShowNodes(!showNodes) }}><i className='fa fa-plus'></i></button>
                </div>
                <div className="btn-group me-2">
                    <button className="btn btn-sm btn-outline-light" onClick={loadFlow}><i className='fa fa-folder-open'></i></button>
                    <button className="btn btn-sm btn-outline-light" onClick={saveFlow}><i className='fa fa-save'></i></button>
                </div>
            </div>

            <div className='container-fluid'>
                <div className="row" style={{ position: 'relative' }}>
                    <div className="col" style={{ height: 'calc(100vh - 250px)' }}>

                        <div style={{ height: 'calc(100vh - 250px)' }}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                nodeTypes={nodeTypes}
                                onEdgeDoubleClick={handleEdgeDoubleClick}
                                onEdgeContextMenu={(event, edge) => event.preventDefault()} // Disable context menu
                                onlyRenderVisibleElements={true}
                                elevateEdgesOnSelect={true}
                                edgeUpdaterRadius={30}
                                defaultEdgeOptions={{
                                    "type": "smoothstep"
                                }}
                                fitView
                                onNodeDoubleClick={handleNodeDoubleClick}
                            >
                               
                                {editingEdge && (
                                    // Display a modal or input fields for editing label and type
                                    // Pass handleEdgeSave as a callback to save changes
                                    <Panel className='panel' position="top-right">
                                        <EdgeEditModal
                                            edge={editingEdge}
                                            onSave={handleEdgeSave}
                                            onCancel={() => setEditingEdge(null)}
                                        /></Panel>
                                )}

                                {showNodes && (<Panel className='panel' position="top-left">
                                    <div
                                        className="panel"
                                    >
                                        <SidePanel nodeTypes={customNodeTypes} addNode={addNode} />
                                    </div></Panel>)}


                                {selectedNodeId && (<Panel className='panel' position="top-right">
                                    <div
                                        className=""
                                        style={{ width: "350px", height: "calc(100vh - 450px)", overflow: "auto" }}
                                    >
                                        <EditPanel selectedNode={selectedNode} unselectNodeId={unselectNodeId} saveEditedNode={saveEditedNode} nodes={nodes} edges={edges} />
                                    </div>
                                </Panel>)}

                                <Controls />
                                <MiniMap />
                                <Background variant="dots" gap={12} size={1} />
                            </ReactFlow>


                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Dashboard;
