import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import user_1 from './assets/user_1.avif';
import user_2 from './assets/user_2.avif';
import user_3 from './assets/user_3.avif';
import user_4 from './assets/user_4.avif';

const TeamMemberCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item  className='p-4'>
        <div className="d-flex ms-5 me-5 ps-5 pe-5">
          <div className="col-md-3 p-4 text-center">
            <img
              className="img-thumbnail img-responsive rounded-circle shadow-4-strong"
              src={user_1}
              alt="Team Member 1"
            />
          </div>
          <div className="col-md-3 text-center p-4">
            <img
              className="img-thumbnail img-responsive rounded-circle shadow-4-strong "
              src={user_1}
              alt="Team Member 2"
            />
          </div>
          <div className="col-md-3 text-center p-4">
            <img
              className="img-thumbnail img-responsive rounded-circle shadow-4-strong"
              src={user_3}
              alt="Team Member 3"
            />
          </div>
          <div className="col-md-3 text-center p-4 ">
            <img
              className="img-thumbnail img-responsive rounded-circle shadow-4-strong"
              src={user_3}
              alt="Team Member 4"
            />
          </div>
        </div>
      </Carousel.Item>
      {/* Add more carousel items for additional team members */}
    </Carousel>
  );
};

export default TeamMemberCarousel;
