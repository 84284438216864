import './App.css';
import React, { useState, useEffect } from 'react';

function App() {
  const [items, setItems] = useState([]); // Array to store items
  const [newItem, setNewItem] = useState({ id: '', name: '', description: '' }); // State for new item form fields

  useEffect(() => {
    // Define the URL of your API endpoint
    const apiUrl = '/api/myresource';

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        setItems(responseData); // Update the items state with the fetched data
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleAddItem = () => {
    // Make an API POST request to add the new item
    const apiUrl = '/api/myresource';
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newItem),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error adding item to API');
        }
        return response.json();
      })
      .then((addedItem) => {
        // Add the newly created item to the items state
        setItems([...items, addedItem]);
        // Clear the form fields after adding
        setNewItem({ id: '', name: '', description: '' });
      })
      .catch((error) => {
        console.error('Error adding item:', error);
      });
  };

  const handleChange = (e) => {
    // Update the newItem state when form fields change
    setNewItem({ ...newItem, [e.target.name]: e.target.value });
  };

  return (
    <div className="App container p-5">
      <header className="App-header">
        <div>
          <form className="p-5 mb-4 bg-white rounded-3">
            <div className="form-group">
              <label htmlFor="id">ID:</label>
              <input
                type="text"
                name="id"
                id="id"
                className="form-control"
                value={newItem.id}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                value={newItem.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description:</label>
              <input
                type="text"
                name="description"
                id="description"
                className="form-control"
                value={newItem.description}
                onChange={handleChange}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={handleAddItem}
            >
              Add New Item
            </button>
          </form>
        </div>

        {items.length > 0 ? (
          <div>
            <h2>Items from API:</h2>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No items found.</p>
        )}
      </header>
    </div>
  );
}

export default App;
