import React from 'react';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Handle, Position,
    NodeToolbar,
    Panel,
    applyEdgeChanges, applyNodeChanges
} from 'reactflow';

// import { useStore } from '../store';

export default function StartNode({ id, data }) {
    return (
        <>
            
            <div
                style={{
                    border: '1px solid #B8B8B8',
                    borderRadius: '8px',
                    padding: '8px',
                    background: 'white',
                }}
            >
                <div className="container" style={{ maxWidth: "250px" }}>
                    <div className="row">
                        
                        <div className="ms-2 mt-2 col-1 node-icon center">
                            <i style={{ color: "green" }} className="mt-1 w-100  fa fa-flag"></i>
                        </div>
                        <div className="mt-2 col node-label">
                            <b className="small p-0 m-0">{data.label}</b>
                            <p className="small">{data.description}</p>
                        </div>
                    </div>
                </div>

            </div>
            <Handle
                type="source"
                position={Position.Bottom}
                id="b"
            />
        </>
    );
};