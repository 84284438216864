import React from 'react';

const SidePanel = ({ nodeTypes, addNode }) => {
  const handleNodeTypeClick = (type, event, label, icon, description, borderColor, inputs, outputs) => {
    // Call the addNode function with the selected properties
    addNode(type, event, label, icon, description, borderColor, inputs, outputs);
  };

  return (
    <div className="side-panel mb-3">
      <h6 className='strong text-strong p-2 border-bottom'>Nodes</h6>
      {Object.entries(nodeTypes).map(([group, types]) => (
        <div className='mt-3  ps-3 pe-3' key={group}>
          <h4>{group}</h4>
          <ul className="list-group" >
            {types.map((nodeType) => (
              <li className="list-group-item"  key={nodeType.label}>
                <div
                  onClick={() =>
                    handleNodeTypeClick(
                      nodeType.type,
                      nodeType.event,
                      nodeType.label,
                      nodeType.icon,
                      nodeType.description,
                      nodeType.borderColor,
                      nodeType.inputs,
                      nodeType.outputs
                    )
                  }
                >
                            <i style={{}} className={`me-2 fa ${nodeType.icon}`}></i>
                  {nodeType.label}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default SidePanel;