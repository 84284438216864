import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';

import App from './App';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import Dashboard from './Dashboard';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <div className="banner" ></div>
      <div className="bg-white text-dark p-5">
        <section className="search-section container">
          <div className="row">
            <div className="col">
              <Navbar />
            </div>
          </div>
        </section>
      </div>

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/app" component={App} />
        <Route path="/dashboard" component={Dashboard} />
      </Switch>

      <div className="bg-dark text-white p-5">
        <section className="footer-section container">
          <Footer />
        </section>
      </div>

    </Router>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

